import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Header } from "./Components/Header";
import { Footer } from "./Components/Footer";
import { Homepage } from "./Components/Homepage";
import { About } from "./Components/sub/About";
import { Contact } from "./Components/sub/Contact";
import Manusingh from "./Components/sub/profile/Manusingh";
import Rajesh from "./Components/sub/profile/Rajesh";

import handeczema from "./Components/assets/handeczema.jpeg";
import psoriasis from "./Components/assets/psoriasis.jpeg";
import acnevulgaris from "./Components/assets/acnevulgaris.webp";
import alopecia from "./Components/assets/alopecia.jpeg";
import keloidsscar from "./Components/assets/keloidsscar.jpeg";
import scalppsoriasis from "./Components/assets/scalppsoriasis.jpeg";
import handvitiligo from "./Components/assets/handvitiligo.gif";
import vitiligo from "./Components/assets/vitiligo.jpeg";
import lichen from "./Components/assets/Lichen.jpg";
import vasculardisorder from "./Components/assets/vasculardisorder.jpeg";
import scar from "./Components/assets/scar.webp";
import pigmentation from "./Components/assets/pigmentation.webp";
import drugreaction from "./Components/assets/drugreaction.jpeg";
import depigmentation from "./Components/assets/depigmentation.jpeg";
import blisteringdisorder from "./Components/assets/blisteringdisorder.jpeg";

import lasers from "./Components/assets/lasers.jpeg";
import antiageing from "./Components/assets/antiageing.jpeg";
import microdermabrasion from "./Components/assets/microdermabrasion.jpeg";
import chemicalpeels from "./Components/assets/chemicalpeels.webp";
import minorsurgical from "./Components/assets/minorsurgical.jpeg";

// import clubfoot from "./Components/assets/clubfoot.jpg";
// import valgusfoot from "./Components/assets/valgusfoot.jpg";
// import hipdislocation from "./Components/assets/hipdislocation.png";
import kneedislocation from "./Components/assets/kneedislocation.jpg";
// import torticollis from "./Components/assets/torticollis.jpg";
// import obpp from "./Components/assets/obpp.jpg";
// import flatfeet from "./Components/assets/flatfeet.jpg";
// import intoegait from "./Components/assets/intoegait.jpg";
// import genuvarus from "./Components/assets/genuvarus.jpg";
import fractures from "./Components/assets/fractures.jpeg";

import manu_profile from "./Components/assets/manu_profile.jpg";
import rajeshProfile from "./Components/assets/rajesh_profile.jpg";
import Skinandhair from "./Components/sub/Skinandhair";
import Jointcare from "./Components/sub/Jointcare";
import Disease from "./Components/sub/Disease";
import Treatment from "./Components/sub/Treatment";
import SubFracture from "./Components/sub/SubFracture";

import content from "./Components/sub/disease/content";

const doc_details_1 = {
  name: "Dr. Manu Singh",
  qual: "MBBS, DVD in Dermatology",
  brief: "Fellowship in Cosmetics and Asthetics",
  desig: "Dermatologist",
  speciality: "Dermatology",
  degree: "MBBS, DVD",
  availabilty: `Mon - Sat : 10 AM - 8 PM 
Sun        :  9 AM - 1 PM `,
};
// const dd_1=[
//   {name:'Speciality', value:"Dermatology"},
//   {name:'Degree', value:'MBBS, DVD, MD'},

// ]
const doc_details_2 = {
  name: "Dr. Rajesh Bhadu",
  qual: "MBBS, MS",
  desig: "Orthopedician",
  brief: "Fellowship in Arthroscopy",
  speciality: "Orthopedician",
  degree: "MBBS, MS, Fellowship in Arthroscopy",
  availabilty: `Tue - Wed : 6:30 PM - 10 PM 
Fri-Sat     : 6:30 PM - 10 PM
Sun        :9 AM - 1 PM`,
};
const contact = {
  phone: `+91 7042022074
+91 9557183639`,
  email: "Skin.shineandortho1122@gmail.com",
  address: "Sector 102, Gurugram, Haryana 122505",
};

const clinical_derm = [
  { name: "Eczema", link: "#", img: handeczema },
  { name: "Psoriasis", link: "#", img: psoriasis },
  { name: "Scalp Psoriasis", link: "#", img: scalppsoriasis },
  { name: "Alopecia", link: "#", img: alopecia },
  { name: "Lichen Planus", link: "#", img: lichen },
  { name: "Acne|Pimples", link: "#", img: acnevulgaris },
  { name: "Keloids", link: "#", img: keloidsscar },
  { name: "Hand Vitiligo", link: "#", img: handvitiligo },
  { name: "Vitiligo", link: "#", img: vitiligo },
  { name: "Scars", link: "#", img: scar },
  { name: "Pigmentation", link: "#", img: pigmentation },
  { name: "Depigmentation", link: "#", img: depigmentation },
  { name: "Blistering disorders", link: "#", img: blisteringdisorder },
  { name: "Drug reactions", link: "#", img: drugreaction },
  { name: "Infections", link: "#" },
  { name: "Vascular disorders", link: "#", img: vasculardisorder },
];

const dermatosurgery = [
  { name: "Lasers", link: "#", img: lasers },
  { name: "Microdermabrasion", link: "#", img: microdermabrasion },
  { name: "Chemical peels", link: "#", img: chemicalpeels },
];

const cosmetic_derm = [
  { name: "Minor surgical procedures", link: "#", img: minorsurgical },
  { name: "Anti-ageing basket", link: "#", img: antiageing },
];

const paediatric = [
  // { name: 'Clubfoot(CTEV)', img: clubfoot },
  // { name: 'Valgus foot', img: valgusfoot },
  // { name: 'Dislocation of the hip', img: hipdislocation },
  { name: "Dislocation of the knee", img: kneedislocation },
  // { name: 'Torticollis', img: torticollis },
  // { name: 'Obstetrical Brachial Plexus Palsy', img: obpp },
  // { name: 'Flat feet', img: flatfeet },
  // { name: 'Intoe Gait', img: intoegait },
  // { name: 'Genu varus', img: genuvarus },
  { name: "Fractures", img: fractures },
];

const combined = [
  ...clinical_derm,
  ...dermatosurgery,
  ...cosmetic_derm,
  ...paediatric,
];
console.log(combined);

function App() {
  return (
    <>
      <BrowserRouter>
        <Header></Header>
        <Routes>
          <Route
            path="/"
            element={
              <Homepage
                doc1={doc_details_1}
                clinical_derm={clinical_derm}
                paediatric={paediatric}
                doc2={doc_details_2}
                pic1={manu_profile}
                pic2={rajeshProfile}
              ></Homepage>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact details={contact} />} />
          <Route
            path="/skin-and-hair"
            element={
              <Skinandhair
                clinical_derm={clinical_derm}
                dermatosurgery={dermatosurgery}
                cosmetic_derm={cosmetic_derm}
              />
            }
          />
          <Route
            path="/joint-care"
            element={<Jointcare paediatric={paediatric} />}
          />
          <Route
            path="/manu-singh"
            element={<Manusingh details={doc_details_1} pic={manu_profile} />}
          />
          <Route
            path="/rajesh"
            element={<Rajesh details={doc_details_2} pic={rajeshProfile} />}
          />
          <Route
            path="/disease/:d_id"
            element={<Disease combined={combined} content={content} />}
          />
          <Route
            path="/treatment/:t_id"
            element={<Treatment combined={combined} content={content} />}
          />
          <Route
            path="/ortho/:o_id"
            element={<Disease combined={combined} content={content} />}
          />
          <Route
            path="/ortho/fractures"
            element={<SubFracture content={content} />}
          />
        </Routes>
        <Footer contact={contact}></Footer>
      </BrowserRouter>
    </>
  );
}

export default App;
