import React from "react";
import { Indicator, Phone, MailOption } from "grommet-icons";
import { useState } from "react";
// import { useEffect } from 'react'

import { AiOutlineInstagram, AiOutlineWhatsApp } from "react-icons/ai";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { send } from "emailjs-com";

import { Box, Layer } from "grommet";
import "./css/footer.css";

export const Footer = (prop) => {
  const [toSend, settoSend] = useState({
    department: "",
    appointment_date: "",
    appointment_time: "",
    name: "",
    phone: "",
    message: "",
  });

  const options = [
    { value: "skin&hair", label: "Skin and Hair Care" },
    { value: "jointcare", label: "Joint Care" },
  ];

  const timeOption = [
    { value: "morning", label: "Morning (9 AM - 12 Noon)" },
    { value: "afternoon", label: "Afternoon (12Noon - 4 PM)" },
    { value: "evening", label: "Morning (4 PM - 9 PM)" },
  ];
  const [show, setShow] = useState();
  const [whatsappshow, setwhatsappshow] = useState();

  const redirect = () => {
    window.location.replace("https://www.instagram.com/manusingh696/");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    send("service_5mxy6wo", "template_bjjt8gs", toSend, "KrEnm4QF7SsA_RIfV")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        toast.success(`We've got your application and contact you soon`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        console.log("FAILED...", err);
        toast.error("Failed to appoint due to some reason", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    console.log(toSend);
  };
  // useEffect(() => {
  //   settoSend({phone: phoneval})
  // }, [phoneval])

  const handleChange = (e) => {
    settoSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="footer-box">
        <div className="footer-heading">GET IN TOUCH</div>
        <div className="footer-links">
          <div className="location">
            <Indicator size="medium" color="red"></Indicator>
            <div>
              <span>Sector 102, Gurugram,</span>
              <span>Haryana 122505</span>
            </div>
          </div>
          <div className="appointment">
            <Box>
              <button
                className="btn-appoint"
                label="show"
                onClick={() => setShow(true)}
              >
                <span>Book Appointment</span>
              </button>
              {show && (
                <Layer
                  responsive={false}
                  onEsc={() => setShow(false)}
                  onClickOutside={() => setShow(false)}
                >
                  <div className="appoint-layer-box">
                    <div className="appointment-form-header">
                      <div className="appointment-form-heading">
                        Appointment Form
                      </div>
                      <button
                        className="close-btn"
                        onClick={() => setShow(false)}
                      >
                        <span>X</span>
                      </button>
                    </div>
                    <div className="appointment-form">
                      <form onSubmit={handleSubmit}>
                        <div className="input-field">
                          <label>Name:</label>
                          <input
                            type="text"
                            name="name"
                            value={toSend.name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="input-field">
                          <label>Phone:</label>
                          <PhoneInput
                            name="phone"
                            value={toSend.phone}
                            onChange={(event) =>
                              settoSend({ ...toSend, phone: event })
                            }
                          ></PhoneInput>
                        </div>
                        <div className="input-field">
                          <label>Select Department:</label>
                          <select
                            name="department"
                            value={toSend.department}
                            onChange={handleChange}
                          >
                            <option>---</option>
                            {options.map((i) => (
                              <option>{i.label}</option>
                            ))}
                          </select>
                        </div>
                        <div className="input-field">
                          <label>Select Date:</label>
                          <input
                            type="date"
                            name="appointment_date"
                            value={toSend.appointment_date}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="input-field">
                          <label>Select Time:</label>
                          <select
                            name="appointment_time"
                            value={toSend.appointment_time}
                            onChange={handleChange}
                          >
                            <option>---</option>
                            {timeOption.map((i) => (
                              <option>{i.label}</option>
                            ))}
                          </select>
                        </div>
                        <div className="input-field">
                          <label>Enter your message:</label>
                          <textarea
                            placeholder="Enter your message"
                            cols="20"
                            rows="10"
                            name="message"
                            value={toSend.message}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-submit">
                          <button
                            className="btn-appoint"
                            onSubmit={() => console.log("form submitted")}
                            type="submit"
                          >
                            <span>Submit</span>
                          </button>
                          <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </Layer>
              )}
            </Box>
          </div>
          <div className="other-details">
            <div className="phone">
              <Phone size="medium" color="red" />
              <div>
                <pre>{prop.contact.phone}</pre>
              </div>
            </div>
            <div className="mail">
              <MailOption size="medium" color="red" />
              <div>
                <pre>{prop.contact.email}</pre>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="footer-text">
            Copyright &copy; 2022 Skin Shine and Ortho Clinic. All right
            reserved.
          </div>
          <div className="footer-icons-links">
            <div className="icons-box" onClick={() => redirect()}>
              <AiOutlineInstagram className="icons" />
            </div>
            <Box>
              <div
                className="icons-box"
                label="whatsappshow"
                onClick={() => setwhatsappshow(true)}
              >
                <AiOutlineWhatsApp className="icons" />
              </div>
              {whatsappshow && (
                <Layer
                  className="layer-box"
                  responsive={false}
                  onEsc={() => setwhatsappshow(false)}
                  onClickOutside={() => setwhatsappshow(false)}
                >
                  <div className="whatsapp-layer">
                    <div>WhatsApp Number</div>
                    <button
                      className="close-btn"
                      onClick={() => setwhatsappshow(false)}
                    >
                      <span>X</span>
                    </button>
                  </div>
                  <div>+91 96507183639</div>
                </Layer>
              )}
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};
