import React from "react";
import "../../css/profile.css";
import { Link } from "react-router-dom";

const Rajesh = (prop) => {
  return (
    <>
      <div className="profile-box">
        <div className="heading-name">{prop.details.name}</div>
        <div className="path">
          <Link to="/">Home</Link>&nbsp;&rarr; {prop.details.name}
        </div>
        <div className="content-box">
          <div className="detail-box">
            <div className="picbox">
              <img src={prop.pic} alt="" />
            </div>
            <div className="info-box">
              <div className="info-head">
                <p className="p-name">{prop.details.name}</p>
                <p className="p-qual">{prop.details.qual}</p>
              </div>
              <div className="info-body">
                <div className="line">
                  <div className="left">Speciality</div>
                  <div className="right">{prop.details.speciality}</div>
                </div>
                <div className="line">
                  <div className="left">Degrees</div>
                  <div className="right">{prop.details.degree}</div>
                </div>
                <div className="line">
                  <div className="left">Availability</div>
                  <div className="right">
                    <pre>{prop.details.availabilty}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-info">
            <div className="profile-heading">Profile</div>
            <div className="profile-content">
              <p>
                He is an exceptionally mild-mannered, understanding, and pays
                attention to the patient cautiously. He is excessively
                fulfilling and gives his legitimate chance to the patient.
              </p>
              <p>
                The doctor completed his MBBS and MS at the Kolhapur University.
                He likewise had a fellowship in Arthroscopy at Ruby Hall
                Hospital, Pune and presently he is working at Max Hospital
                Gurugram.
              </p>
              <p>
                He is exceptionally devoted to fill in as he had been assistant
                professor in World Medical College and has a work experience of
                complete nine years.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rajesh;
