import React from "react";
import { Link } from "react-router-dom";

import logo from "./assets/logo.png";

import "./css/header.css";

export const Header = () => {
  return (
    <div className="header-box">
      <div className="header-logo">
        <Link to="/">
          <img width={"125px"} src={logo} alt="" />
        </Link>
      </div>
      <div className="header-links">
        <Link to="/">Home</Link>
        <Link to="/about">About Us</Link>
        <Link to="/skin-and-hair">Skin and Haircare</Link>
        <Link to="/joint-care">Joint Care</Link>
        <Link to="/contact">Contact Us</Link>
      </div>
    </div>
  );
};
