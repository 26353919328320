import React from "react";
import "../css/services.css";

import { Image, Text } from "grommet";

// import { Navigate, useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

export default function Skinandhair(prop) {
    let navigate = useNavigate();

    return (
        <>
            <div className="service-box">
                <div className="service-heading">Skin and HairCare Services</div>
                <div className="service-sub-box">
                    <div className="service-sub-heading">Clinical Dermatology</div>
                    <div className="service-container">
                        {prop.clinical_derm.map((i, index) => {
                            return (
                                <div
                                    key={index}
                                    className="service-content-box"
                                    onClick={() => navigate(`/disease/${i.name}`)}
                                >
                                    <Image
                                        fill
                                        src={
                                            i.img
                                                ? i.img
                                                : "https://source.unsplash.com/300x300/?skin"
                                        }
                                    />
                                    <div className="service-top-text">
                                        <Text>{i.name}</Text>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="service-sub-heading">Dermatosurgery</div>
                    <div className="service-container">
                        {prop.dermatosurgery.map((i, index) => {
                            return (
                                <div
                                    key={index}
                                    className="service-content-box"
                                    onClick={() => navigate(`/treatment/${i.name}`)}
                                >
                                    <Image
                                        fill
                                        src={
                                            i.img
                                                ? i.img
                                                : "https://source.unsplash.com/300x300/?hair"
                                        }
                                    />
                                    <div className="service-top-text">
                                        <Text>{i.name}</Text>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="service-sub-heading">Cosmetic Dermatology</div>
                    <div className="service-container">
                        {prop.cosmetic_derm.map((i) => {
                            return (
                                <div
                                    className="service-content-box"
                                    onClick={() => navigate(`/treatment/${i.name}`)}
                                >
                                    <Image
                                        fill
                                        src={
                                            i.img
                                                ? i.img
                                                : "https://source.unsplash.com/300x300/?skin,hair"
                                        }
                                    />
                                    <div className="service-top-text">
                                        <Text>{i.name}</Text>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}
