const content = [
    {
        name: 'Eczema',
        text: `An itchy inflammation of the skin.
Atopic dermatitis usually develops in early childhood and is more common in people who have a family history of the condition.
Treatment includes avoiding soap and other irritants.Certain creams or ointments may also provide relief from the itching.`,
        symptoms: `The main symptom is a rash that typically appears on the arms and behind the knees, but can also appear anywhere.`,
    },
    {
        name: 'Psoriasis',
        text: `A condition in which skin cells build up and form scales and itchy, dry patches.
        Psoriasis is thought to be an immune system problem. Triggers include infections, stress and cold.
        Treatment aims to remove scales and stop skin cells from growing so quickly. Topical ointments, light therapy and medication can offer relief.`,
        symptoms: `The most common symptom is a rash on the skin, but sometimes the rash involves the nails or joints.`,
    },
    {
        name: 'Scalp Psoriasis',
        text: `Scalp psoriasis is a common skin disorder that makes raised, reddish, often scaly patches. It can pop up as a single patch or several, and can even affect your entire scalp. It can also spread to your forehead, the back of your neck, or behind and inside your ears. You can't catch scalp psoriasis from another person.`,
        symptoms: `Symptoms of mild scalp psoriasis may include only slight, fine scaling.Scalp psoriasis itself doesn’t cause hair loss, but scratching a lot or very hard, picking at the scaly spots, harsh treatments, and the stress that goes along with the condition can lead to temporary hair loss. Fortunately, your hair usually grows back after your skin clears.`
    },
    {
        name: 'Alopecia',
        text: `Sudden hair loss that starts with one or more circular bald patches that may overlap.
        Alopecia areata occurs when the immune system attacks hair follicles and may be brought on by severe stress.`,
        symptoms: `The main symptom is hair loss.`
    },
    {
        name: 'Lichen Planus',
        text: `An inflammatory condition of the skin and mucous membranes.
        Lichen planus occurs when the immune system mistakenly attacks cells of the skin or mucous membranes.
        Lichen planus usually goes away on its own. If symptoms are bothersome, topical creams and oral antihistamines may help.`,
        symptoms: `On skin, lichen planus appears as purplish, itchy, flat-topped bumps. On mucous membranes, such as in the mouth, it forms lacy, white patches, sometimes with painful sores.`,
    },
    {
        name: 'Acne|Pimples',
        text: `A skin condition that occurs when hair follicles plug with oil and dead skin cells.
        Acne is most common in teenagers and young adults.
        Treatments include over-the-counter creams and cleanser, as well as prescription antibiotics.
        `,
        symptoms: `Symptoms range from uninflamed blackheads to pus-filled pimples or large, red and tender bumps.`
    },
    {
        name: 'Keloids',
        text: `A raised scar after an injury has healed.
        A keloid is caused by an excess of a protein (collagen) in the skin during healing.
        Keloids aren't harmful and don't need treatment. If a person finds them unattractive, a doctor can sometimes minimise the scars.`,
        symptoms: `Keloids are often lumpy or ridged. The scar rises after an injury or condition has healed, such as a surgical incision or acne.`,
    },
    {
        name: 'Hand Vitiligo',
        text: `Vitiligo (vit-ih-LIE-go) is a disease that causes loss of skin color in patches. The discolored areas usually get bigger with time. The condition can affect the skin on any part of the body. It can also affect hair and the inside of the mouth.`,
        symptoms: `Patchy loss of skin color, which usually first appears on the hands, face, and areas around body openings and the genitals. Premature whitening or graying of the hair on your scalp, eyelashes, eyebrows or beard. Loss of color in the tissues that line the inside of the mouth and nose (mucous membranes)`
    },
    {
        name: 'Vitiligo',
        text: `A disease that causes the loss of skin colour in blotches.
        Vitiligo occurs when pigment-producing cells die or stop functioning.
        Treatment may improve the appearance of the skin but doesn't cure the disease.`,
        symptoms: `Loss of skin colour can affect any part of the body, including the mouth, hair and eyes. It may be more noticeable in people with darker skin.`
    },
    {
        name: 'Scars',
        text: `Scars form as part of the body's healing process. Your body builds tissue to repair damaged skin and close gaps due to an injury. Scars come in all shapes and sizes. They can result from accidents, burns, surgery, acne and illness. Over time, most scars fade away. Several treatments can make scars less noticeable.`,
        symptoms: `Sometimes scar tissue can be painless. When it comes to scar tissue on your skin, you may notice it has a thicker texture compared to the rest of your body and that’s it. Scar tissue you can’t see may form due to internal wounds, surgeries, or underlying diseases. You may still feel pain and stiffness at these sites, especially if the scar tissue starts affecting the surrounding joints. Such is the case with knee or spinal scar tissue, as well as scar tissue formed following surgeries of the face, or from medical procedures like hysterectomies`
    },
    {
        name: 'Pigmentation',
        text: `Pigmentation means coloring. Skin pigmentation disorders affect the color of your skin. Your skin gets its color from a pigment called melanin. Special cells in the skin make melanin`,
        symptoms: `Pigmentation is visible in the form of patches and varies in shape and size. They develop in any area of the body and differ depending on the type of hyperpigmentation.`
    },
    {
        name: 'Depigmentation',
        text: `Depigmentation is the lightening of the skin or loss of pigment. Depigmentation of the skin can be caused by a number of local and systemic conditions. The pigment loss can be partial (injury to the skin) or complete (caused by vitiligo).`,
        symptoms: `Lightened or white skin that develops in spots or patches on the face. Hair that gets prematurely gray or white, including your beard, eyelashes, and eyebrows. Lightening of the tissues inside your mouth and nose.
        `
    },
    {
        name: 'Blistering disorders',
        text: 'A blister (bulla, or, when small, a vesicle) is a bubble of fluid that forms beneath a thin layer of dead skin. The fluid is a mixture of water and proteins that oozes from injured tissue. Blisters most commonly form in response to a specific injury, such as a burn or irritation, and usually involve only the topmost layers of skin. These blisters heal quickly, usually without leaving a scar. Blisters that develop as part of a systemic (bodywide) disease may start in the deeper layers of the skin and cover widespread areas. These blisters heal more slowly and may leave scars.',
        symptoms: ` Itching skin, weeks or months before blisters form. Large blisters that don't easily rupture when touched, often along creases or folds in the skin. Skin around the blisters that is normal, reddish or darker than normal.`
    },
    {
        name: 'Drug reactions',
        text: `A drug interaction is a change in the way a drug acts in the body when taken with certain other drugs, foods, or supplements or when taken while you have certain medical conditions.`,
        symptoms: `Hives, rash and fever are common symptoms.
        `,
    },
    {
        name: 'Infections',
        text: `Your skin is your body's largest organ. It has many different functions, including covering and protecting your body. It helps keep germs out. But sometimes the germs can cause a skin infection. This often happens when there is a break, cut, or wound on your skin. It can also happen when your immune system is weakened, because of another disease or a medical treatment.
        Some skin infections cover a small area on the top of your skin. Other infections can go deep into your skin or spread to a larger area.`,
        symptoms: `The symptoms depend on the type of infection. Some symptoms that are common to many skin infections include rashes, swelling, redness, pain, pus, and itching.`,
    },
    {
        name: 'Vascular disorders',
        text: 'Vascular disease includes any condition that affects your circulatory system, or system of blood vessels. This ranges from diseases of your arteries, veins and lymph vessels to blood disorders that affect circulation. Blood vessels are elastic-like tubes that carry blood to every part of your body.',
        symptoms: `Wounds that won't heal over pressure points, such as heels or ankles.
        Numbness, weakness, or heaviness in muscles.
        Burning or aching pain at rest, commonly in the toes and at night while lying flat.
        Restricted mobility.
        Thickened, opaque toenails.
        Varicose veins.`
    },
    {
        name: "Clubfoot(CTEV)",
        text: `A birth defect in which the foot is twisted out of shape or position.
        The cause of clubfoot is unknown. Babies with clubfoot are usually otherwise healthy.
        Treatment is usually successful. It includes stretching and casting (Ponseti method) or stretching and taping (French method). Sometimes, surgery is required.`,
        symptoms:`In clubfoot, the foot appears twisted and can even look as if it's upside down. Despite its appearance, clubfoot itself doesn't cause any discomfort or pain.`,
    },
    {
        name:'Valgus foot',
        text:`A Hallux abducto valgus is a foot deformity consisting in the deviation of the big toe or hallux outward, that is, towards the smaller toes. It is commonly known as a bunion, although bunion probably refers more to the bump which arises from this disorder due to the prominence of the first metatarsal head on the medial edge of the foot, and the exostosis or bone growth which tends to accompany this disorder, although this exostosis may also occur in other foot disorders like hallux rigidus.`,
        symptoms:`This disorder may cause hardly any symptoms and be easily tolerated, or, on the contrary, it may cause intense pain in different locations, especially in the bunion area. This pain fundamentally arises from rubbing against the shoe, causing inflammation (bursitis), pain in the metatarsal phalangeal joint due to its deterioration, pain in the sole of the foot, pain in the metatarsal heads (mainly between the second and third), metatarsalgia resulting from overloading when support of the first toe fails (first ray insufficiency), pain and deformity in the smaller toes (hammer toe, claw toe), caused by the displacement of the first toe, or pain arising from some other cause.`
    },
    {
        name:'Dislocation of the hip',
        text:`An injury in which the hip bone is displaced from its normal position.
        Motor vehicle accidents are a common cause of hip dislocation. In infants, this condition may occur as a result of congenital looseness in the joint. Once a hip is dislocated, chances increase that it will happen again.
        Immediate medical treatment is required and involves pushing the bone into place by hand or surgery, and rehabilitation.`,
        symptoms:`The hip may be swollen, painful and visibly out of place. The leg may be hard to move or may feel tingly or numb.`
    },
    {
        name:'Dislocation of the knee',
        text:`A dislocated knee is when the three bones of your knee are out of place and aren’t aligned the way they should be. It can happen if the structures in your knee are abnormal. Some people are born with a knee dislocation (congenital dislocation of the knee). Most of the time, knee dislocations happen when a traumatic event thrusts the bones in your knee joint out of place with great force. It’s an emergency, and it's very painful.`,
        symptoms:'When you dislocate your knee, you may hear a popping sound. It hurts a lot. Your knee is in so much pain that you can’t move or straighten it. Your knee feels unstable. It’s swollen and severely bruised. Parts of the knee look like they’ve been knocked out of place. You aren’t able to do activities or sports you normally do.'
    },
    {
        name:'Torticollis',
        text:`A rare condition in which the neck muscles contract, causing the head to twist to one side.
        Torticollis can be inherited and is seen soon after birth. This form may resolve without treatment.
        Injecting botulinum toxin into the affected muscles often reduces the symptoms of torticollis. Physiotherapy or surgery may also be required.`,
        symptoms:`The most common type causes the chin to pull toward the shoulder. Symptoms include a limited range of head motion, headache or neck pain.`,
    },
    {
        name:'Obstetrical Brachial Plexus Palsy',
        text:`Obstetrical brachial plexus palsy (OBPP) is a devastating complication associated with difficult or assisted delivery. Newborns may sustain increased forces of distraction on the neck during passage through the birth canal, which in turn put excessive stress on the brachial plexus, causing nerve injury. Although many infants will recover with either no functional deficit or a minor one, others will not regain adequate limb function.`,
        symptoms:`An imbalance at the shoulder between the few, lastingly paralyzed external rotators (injury to the suprascapular nerve) and the strong group of internal rotators.`,
    },
    {
        name:'Flat feet',
        text:`A condition in which the entire sole of the foot touches the floor when standing.
        Flat feet can occur during childhood if the arches of the feet don't develop, after an injury or from the wear and tear of ageing.
        For painful flat feet, treatments include arch supports for the shoes, stretching or a structurally supportive shoe.`,
        symptoms:`Most people have no symptoms associated with flat feet. But some people with flat feet experience foot pain, particularly in the heel or arch area.`,
    },
    {
        name:'Intoe Gait',
        text:`​In-toe gait (also called pigeon toe), is very common in children aged 1-4, from when they first start to walk. The child’s foot is positioned on the ground in such a way that it doesn’t point straight ahead but turns a little towards the center. The sole rests well on the ground and it doesn’t hurt. It is almost always symmetrical as well (ie both feet).`,
        symptom:'',
    },
    {
        name:'Genu varus',
        text:`This condition may present from infancy through adulthood and has a wide variety of causes. As it becomes more severe, the patient may exhibit lateral knee thrust and a waddling gait. There may be associated in-toeing and secondary effects on the hip and ankle. The problem may be unilateral, with a functional limb-length discrepancy, or bilateral. The family and medical history may reveal clues to the likelihood of persistence or progression.`,
        symptoms:`Knee or hip pain, Foot or ankle pain, Feet not touching while standing with knees together,
        Stiff or sore joints, A limp while walking, Reduced range of motion in hips, Difficulty walking or running, Knee instability, Progressive knee arthritis in adults, Patients or parents may be unhappy with aesthetics`
    },
    {
        name:'Fractures',
        text:`A complete or partial break in a bone.
        Causes of bone fractures include trauma, overuse and diseases that weaken bones.
        Treatment often involves resetting the bone in place and immobilising it in a cast or splint to give it time to heal. Sometimes, surgery with rods, plates and screws may be required.`,
        symptoms:'The main symptom is pain. There may also be loss of functionality depending on the area affected.',
    }
]


export default content;