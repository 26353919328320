import React from "react";
import "./css/homepage.css";
import { useNavigate } from "react-router-dom";

import { Box, Image, Text } from "grommet";

import { Carousel as Carouselsmall } from "grommet";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Link } from "react-router-dom";

import ss1 from "./assets/ss1.jpg";
import ss2 from "./assets/ss2.jpg";
import ss3 from "./assets/ss3.jpg";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

export const Homepage = (prop) => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        height="60vh"
        width="100vw"
        pad={{ top: "10px", bottom: "10px" }}
        overflow="hidden"
      >
        <Carouselsmall fill>
          <Image fit="cover" src={ss1} />
          <Image fit="cover" src={ss2} />
          <Image fit="cover" src={ss3} />
        </Carouselsmall>
      </Box>
      <div className="meet">
        <div className="meet-heading">
          Meet Our&nbsp;<b>Specialists</b>
        </div>
        <div className="profile-box-main">
          <div className="first-profile">
            <img
              className="pro-image"
              src={prop.pic1}
              width="300px"
              alt="imagehere"
            ></img>
            <div className="doc-name">{prop.doc1.name}</div>
            <div className="qual">{prop.doc1.qual}</div>
            <div className="brief">{prop.doc1.brief}</div>
            <Link to="/manu-singh">View profile</Link>
          </div>
          <div className="second-profile">
            <img
              className="pro-image"
              src={prop.pic2}
              width="300px"
              alt="imagehere2"
            ></img>
            <div className="doc-name">{prop.doc2.name}</div>
            <div className="qual">{prop.doc2.qual}</div>
            <div className="brief">{prop.doc2.brief}</div>
            <Link to="/rajesh">View Profile</Link>
          </div>
        </div>
      </div>
      <div className="skin-services">
        <div className="scroller-heading">
          <div className="heading-text">
            Skin and Hair Care&nbsp;<b>Services</b>
          </div>
          <div className="viewmore">
            <Link to="/skin-and-hair">View More&rarr;</Link>
          </div>
        </div>
        <div className="scroller">
          <Carousel
            responsive={responsive}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            itemClass="carousel-item-padding-40-px"
          >
            {prop.clinical_derm.map((i) => {
              return (
                <div
                  className="pic-box"
                  onClick={() => navigate(`/disease/${i.name}`)}
                >
                  <Image
                    fill
                    src={
                      i.img
                        ? i.img
                        : "https://source.unsplash.com/885x342/?ortho"
                    }
                  />
                  <div className="top-text">
                    <Text color={"white"}>{i.name}</Text>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
      <div className="ortho-services">
        <div className="scroller-heading">
          <div className="heading-text">
            Joint Care&nbsp;<b>Services</b>
          </div>
          <div className="viewmore">
            <Link to="/joint-care">View More&rarr;</Link>
          </div>
        </div>
        <div className="scroller">
          <Carousel
            responsive={responsive}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            itemClass="carousel-item-padding-40-px"
          >
            {prop.paediatric.map((i) => {
              return (
                <div
                  className="pic-box"
                  onClick={() => navigate(`/ortho/${i.name}`)}
                >
                  <Image
                    fill
                    src={
                      i.img
                        ? i.img
                        : "https://source.unsplash.com/885x342/?ortho"
                    }
                  />
                  <div className="top-text">
                    <Text color={"white"}>{i.name}</Text>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};
