import React from 'react'
import SubTreatment from './treatment/SubTreatment'
import treatments from './treatment/treatments'

import { useParams } from 'react-router-dom'

const Treatment = (prop) => {
    const id = useParams();
    const treatment = prop.combined.find(i => i.name === id.t_id);
    console.log('treat', treatment)
    const treatContent = treatments.find(i => i.name === treatment.name);
    console.log(treatContent);
    return (<>
        <SubTreatment treatment={treatment} treatContent={treatContent} />
    </>
    )
}

export default Treatment