import React from "react";
import { Image } from "grommet";
import "../css/subfracture.css";

import fracture1 from "../assets/fracture1.jpeg";
import fracture2 from "../assets/fracture2.jpeg";
import fracture3 from "../assets/fracture3.jpeg";
import fracture4 from "../assets/fracture4.jpeg";
import fracture5 from "../assets/fracture5.jpeg";

const SubFracture = (prop) => {
  return (
    <>
      <>
        <div className="disease-box">
          <div className="problem-box">
            <div className="fracture-heading">Fracture</div>
            <div className="fracture-main">
              <div className="fracture-content">
                <div className="fracture-image">
                  <Image fill src={fracture1} alt="" />
                </div>
                <div className="fracture-image">
                  <Image fill src={fracture2} alt="" />
                </div>
                <div className="fracture-image">
                  <Image fill src={fracture3} alt="" />
                </div>
                <div className="fracture-image">
                  <Image fill src={fracture4} alt="" />
                </div>
                <div className="fracture-image">
                  <Image fill src={fracture5} alt="" />
                </div>
              </div>
              <div className="disease-desc">
                <div className="overveiw">
                  <div className="overview-heading">Overview</div>
                  <div className="overview-content">
                    A complete or partial break in a bone. Causes of bone
                    fractures include trauma, overuse and diseases that weaken
                    bones. Treatment often involves resetting the bone in place
                    and immobilising it in a cast or splint to give it time to
                    heal. Sometimes, surgery with rods, plates and screws may be
                    required.
                  </div>
                </div>
                <div className="treatment">
                  <div className="overview-heading">Symptoms</div>
                  <div className="overview-content">
                    The main symptom is pain. There may also be loss of
                    functionality depending on the area affected.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SubFracture;
