import React from "react";
import "../css/services.css";

import { Image, Text } from "grommet";
import { useNavigate } from "react-router-dom";

export default function Jointcare(prop) {
  const navigate = useNavigate();
  return (
    <>
      <div className="service-box">
        <div className="service-heading">Joint Care</div>
        <div className="service-container">
          {prop.paediatric.map((i) => {
            return (
              <div
                className="service-content-box"
                onClick={() => navigate(`/ortho/${i.name}`)}
              >
                <Image
                  fill
                  src={
                    i.img ? i.img : "https://source.unsplash.com/885x342/?ortho"
                  }
                />
                <div className="service-top-text">
                  <Text>{i.name}</Text>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
