import React from 'react'
import '../../css/disease.css'


const SubTreatment = (prop) => {
    return (
        <>
            <div className="disease-box">
                <div className="problem-box">
                    <div className='disease-heading'>
                        {prop.treatment.name}
                    </div>
                    <div className="disease-content">
                        <div className="dis-image">
                            <img src={prop.treatment.img} width='500px' alt='' />
                        </div>
                        <div className="disease-desc">
                            <div className="overveiw">
                                <div className="overview-heading">
                                    Overview
                                </div>
                                <div className="overview-content">
                                    {prop.treatContent.text ? prop.treatContent.text : 'Sample Text'}
                                </div>
                            </div>
                            {/* <div className="treatment">
                                <div className="overview-heading">
                                    Symptoms
                                </div>
                                <div className="overview-content">
                                    {prop.treatContent.symptoms ? prop.treatContent.symptoms : 'Sample Text'}
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* <div className="treatment">
                    <div className="treatment-heading">
                        Treatment
                    </div>
                    <div className="treatment-content">
                        <div className="treatment-img" >
                            <img src={prop.disease.img} alt='' />
                            <div className='treatment-text'>text</div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default SubTreatment