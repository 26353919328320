import React from "react";
import { useState } from "react";

import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";
import "../css/contact.css";

import { send } from "emailjs-com";

export const Contact = (prop) => {
  const [toSend, settoSend] = useState({
    from_name: "",
    from_email: "",
    mail_subject: "",
    mail_message: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    send("service_5mxy6wo", "template_em72b0j", toSend, "KrEnm4QF7SsA_RIfV")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        toast.success("Thank you for your response", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        console.log("FAILED...", err);
        toast.error("Response not Submitted", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleChange = (e) => {
    settoSend({ ...toSend, [e.target.name]: e.target.value });
  };
  console.log(toSend);

  return (
    <>
      <div className="contact-box">
        <div className="heading-name">Contact</div>
        <div className="path">
          <Link to="/">Home</Link>&nbsp;&rarr; Contact
        </div>
        <div className="main-box">
          <div className="map-box">
            <iframe
              title="location_map"
              className="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2040.7110729194326!2d76.96328344406825!3d28.473361500452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d17e9f5f79acf%3A0xd6d264077ad11067!2sSkin%20shine%20and%20ortho%20clinic!5e0!3m2!1sen!2sin!4v1659629224103!5m2!1sen!2sin"
              allowFullScreen={true}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
          <div className="cont-details">
            <div className="contact-maingrid">
              <div className="grid-content">Phone</div>
              <div className="grid-content">
                <pre>{prop.details.phone}</pre>
              </div>
              <div className="grid-content">Email</div>
              <div className="grid-content">{prop.details.email}</div>
              <div className="grid-content">Address</div>
              <div className="grid-content">{prop.details.address}</div>
            </div>
          </div>
        </div>

        <div className="contact-form">
          <div className="contact-heading">We'd Love to Hear From You</div>
          <div className="form-def">
            <form onSubmit={handleSubmit}>
              <div className="inputs">
                <input
                  placeholder="Enter your name"
                  type="text"
                  name="from_name"
                  value={toSend.from_name}
                  onChange={handleChange}
                />
                <input
                  placeholder="Enter your Email"
                  type="email"
                  name="from_email"
                  value={toSend.email}
                  onChange={handleChange}
                />
                <input
                  placeholder="Subject"
                  type="text"
                  name="mail_subject"
                  value={toSend.mail_subject}
                  onChange={handleChange}
                />
              </div>
              <textarea
                placeholder="Message"
                name="mail_message"
                // cols="30"
                rows="10"
                value={toSend.mail_message}
                onChange={handleChange}
              />
              <button className="btn-appoint" type="submit">
                <span>Submit</span>
              </button>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
