import React from 'react'
import SubDisease from './disease/SubDisease'

import { useParams } from 'react-router-dom'

const Disease = (prop) => {
  const id = useParams();
  console.log('id', id)
  if (Object.keys(id)[0] === 'o_id') {
    const disease = prop.combined.find(i => i.name === id.o_id);
    console.log('dis', disease)
    const disContent = prop.content.find(i => i.name === disease.name);
    console.log(disContent);
    return (
      <>
        <SubDisease disease={disease} disContent={disContent} />
      </>
    )
  }
  if (Object.keys(id)[0] === 'd_id') {
    const disease = prop.combined.find(i => i.name === id.d_id);
    console.log('dis', disease)
    const disContent = prop.content.find(i => i.name === disease.name);
    console.log(disContent);
    return (<>
      <SubDisease disease={disease} disContent={disContent} />
    </>
    )
  }
}

export default Disease