import React from "react";
// import { List } from 'grommet';
import { Link } from "react-router-dom";
import "../../css/profile.css";

const Manusingh = (prop) => {
  console.log(prop.details);
  return (
    <>
      <div className="profile-box">
        <div className="heading-name">{prop.details.name}</div>
        <div className="path">
          <Link to="/">Home</Link>&nbsp;&rarr; {prop.details.name}
        </div>
        <div className="content-box">
          <div className="detail-box">
            <div className="picbox">
              <img src={prop.pic} width="400px" alt="profile_pic_manu_singh" />
            </div>
            <div className="info-box">
              <div className="info-head">
                <p className="p-name">{prop.details.name}</p>
                <p className="p-qual">{prop.details.qual}</p>
              </div>
              <div className="info-body">
                <div className="line">
                  <div className="left">Speciality</div>
                  <div className="right">{prop.details.speciality}</div>
                </div>
                <div className="line">
                  <div className="left">Degrees</div>
                  <div className="right">{prop.details.degree}</div>
                </div>
                <div className="line">
                  <div className="left">Availability</div>
                  <div className="right">
                    <pre>{prop.details.availabilty}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-info">
            <div className="profile-heading">Profile</div>
            <div className="profile-content">
              <p>
                The main priority of her work is to satisfy the patient. She is
                very hardworking, interactive, and listens keenly to the
                patient's needs.
              </p>
              <p>
                In her academic years, she had completed her MBBS and
                postgraduate degree at Datta Meghe Institute Of Medical
                Sciences.
              </p>
              <p>
                As one of the best dermatologists in Gurugram, she has
                experience at Fortis Hospital Gurugram and was a member of the
                IADVL academic group.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Manusingh;
