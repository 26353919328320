const treatments = [
    {
        name: 'Lasers',
        text: `
        The laser's microscopic treatment causes the body to produce more collagen, resulting in skin tightening and improving the skin's overall texture and complexion. In addition, state-of-the-art laser techniques and intense pulsed light (IPL) devices are also used to successfully remove unwanted facial and body hair.`,
    },
    {
        name: 'Anti-ageing basket',
        text: `Anti-aging facial is a treatment which is meant to treat or prevent ageing symptoms in the human body especially in the skin. Usually, anti-ageing facials are aimed at slowing the aging process, brighten skin, and reduce wrinkles through various kind of products and techniques. Anti-ageing treatments, to some extent, make a person look younger than his/her age.`,
    },
    {
        name: 'Microdermabrasion',
        text: `Microdermabrasion is a minimally invasive procedure used to renew overall skin tone and texture. It can improve the appearance of sun damage, wrinkles, fine lines, age spots, acne scarring, melasma, and other skin-related concerns and conditions.
        The procedure uses a special applicator with an abrasive surface to gently sand away the thick outer layer of the skin to rejuvenate it.   
        A different microdermabrasion technique sprays fine particles of aluminum oxide or sodium bicarbonate with a suction to accomplish the same outcome as using the abrasive surface. Read on to learn more about the procedure.`,
    },
    {
        name: 'Chemical peels',
        text: `A chemical peel is a procedure in which a chemical solution is applied to the skin to remove the top layers. The skin that grows back is smoother. With a light or medium peel, you may need to undergo the procedure more than once to get the desired results.
        Chemical peels are used to treat wrinkles, discolored skin and scars — usually on the face. They can be done alone or combined with other cosmetic procedures. And they can be done at different depths, from light to deep. Deeper chemical peels offer more-dramatic results but also take longer to recover from`,
    },
    {
        name: 'Minor surgical procedures',
        text: `Minor surgical procedures are those that are minimally invasive. In most cases, these are performed laparoscopically or arthroscopically. Small incisions are made that allow surgical tools and a small camera to be inserted into the body. This allows the doctor to perform the procedure without damaging extensive amounts of tissue. The risk of infection is greatly reduced and the patient's recovery time is much shorter. There are also surgical procedures that are superficial, only affecting the outermost portions of the body.`,
    },
]

export default treatments