import React from "react";
import "../css/about.css";

import { Link } from "react-router-dom";

export const About = () => {
  return (
    <>
      <div className="about-box">
        <div className="heading-name">About Us</div>
        <div className="path">
          <Link to="/">Home</Link>&nbsp;&rarr; About Us
        </div>
        <div className="about-main">
          We offer dermatological as well as orthopedic services to patients of
          all ages. These include everyday skin conditions related to Skin,
          Hair, Nails and Mucous membranes (e.g., lining inside the mouth, nose,
          and eyelids) as well as musculoskeletal problems (e.g., Bone fractures
          , joint and spine related problems ) . The dermatological services
          offers not only to help in treating existent problems but also in
          improving the appearance of their patients’ skin, hair, and nails,
          along with orthopedic services offers improvement in daily routine
          activities and stature of the patient.
        </div>
      </div>
    </>
  );
};
